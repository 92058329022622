<template>
    <section class="openMenu" @click="toggleMenu">
        <i class="bi bi-list"></i>
    </section>
    <section class="menu" v-if="showMenu">
        <ul>
            <h3>Leilões</h3>
            <hr>
            <li @click="this.$router.push('/judicial'); toggleMenu()"> Judicial </li>
            <li @click="this.$router.push('/alienacaofiduciaria'); toggleMenu()"> Alienação Fiduciaria </li>
            <li @click="this.$router.push('/vendadireta'); toggleMenu()"> Venda Direta </li>
            <li @click="this.$router.push('/presencial'); toggleMenu()"> Presencial </li>
            <li @click="this.$router.push('/alerte'); toggleMenu()"> Alerte </li>
            <li @click="this.$router.push('/preleilao'); toggleMenu()"> Pré-leilão </li>
            <li @click="this.$router.push('/regularizacao'); toggleMenu()"> Regularização </li>
            <hr>
            <li @click="this.$router.push('/calendar'); toggleMenu()"><i class="bi bi-calendar-check"></i> Agenda</li>
        </ul>
    </section>
</template>
<script>
export default {
    name: 'MenuComponent',
    data() {
        return {
            showMenu: false,
        }
    },
    methods: {
        toggleMenu() {
            if (this.showMenu === false) {
                this.showMenu = true
            } else {
                this.showMenu = false
            }
        }
    }
}
</script>
<style scoped>
.openMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #003d58;
    z-index: 999;

    color: white;
    font-size: 2em;
    font-weight: bold;
    padding: 0 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.263);
    cursor: pointer;
    transition: background .3s;
}

.openMenu:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: transparent;
}

.menu {
    display: flex;
    flex-direction: column;

    position: absolute;
    left: 50px;
    top: 0;
    height: 100%;
    background-color: #003d58;
    z-index: 998;

    color: white;
    padding: 0 15px;
}

.menu ul {
    margin: 0%;
    padding: 0px;
    padding-right: 70px;
    padding-left: 5px;
    padding-top: 50px;
}

.menu ul li {
    display: block;
    margin: 13px 0;
    cursor: pointer;
}
</style>