<template>
  <router-view/>
  <MenuComponent v-if="this.$route.path !== '/' && this.$route.path !== '/register'" />
</template>
<script>
  import MenuComponent from './components/MenuComponent.vue';

  export default {
    name: 'App',
    components: {
      MenuComponent
    }
  }
</script>
<style>
</style>
